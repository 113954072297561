:root {
  --text-color: #3f3f3f;
  --background-color: #FFFFFF;
}    
:root {
  --joy-color:#edc500;
  --trust-color:#7abd0d;
  --fear-color: #007b33;
  --surprise-color: #0080ab;
  --sadness-color: #1f6dad;
  --disgust-color: #7b4ea3;
  --anger-color: #dc0047;
  --anticipation-color: #e87200;
}
@font-face {
  font-family: "UbuntuMono";
  src: url("UbuntuMono-Regular.ttf");
}
* {
  box-sizing: border-box;
}
html,
body {
  font-family: "UbuntuMono", monospace;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow:hidden;
  width: 100%;
  height:100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  /*font-size: calc(10px + 2vmin);*/
}
#root{
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

input, button, select, textarea {
  font-family: 'UbuntuMono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  /*font-size: calc(10px + 2vmin);*/
  border-radius: 5px;
  color: var(--text-color);
  border: var(--text-color) 1px solid;
  background-color: var(--background-color);
  font-size:medium;
  margin: 1px;
  padding: 5px;
  max-width: 90%;
}

button {
  color: var(--background-color);
  border: var(--text-color) 1px solid;
  background-color: var(--text-color);
  cursor: pointer;
}
