body {
    padding: 5px;
    font-size: 120%;
}
.person-greetings-container, .quiz-container {
    position: relative;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    height: 100%;
    padding-top: 1em;
}

.person-greetings-text {
    text-align: center;
}

.question-options-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

